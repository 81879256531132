// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-policy-js": () => import("./../../../src/pages/about/policy.js" /* webpackChunkName: "component---src-pages-about-policy-js" */),
  "component---src-pages-about-terms-js": () => import("./../../../src/pages/about/terms.js" /* webpackChunkName: "component---src-pages-about-terms-js" */),
  "component---src-pages-buy-js": () => import("./../../../src/pages/buy.js" /* webpackChunkName: "component---src-pages-buy-js" */),
  "component---src-pages-estimators-js": () => import("./../../../src/pages/estimators.js" /* webpackChunkName: "component---src-pages-estimators-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invest-js": () => import("./../../../src/pages/invest.js" /* webpackChunkName: "component---src-pages-invest-js" */),
  "component---src-pages-listings-js": () => import("./../../../src/pages/listings.js" /* webpackChunkName: "component---src-pages-listings-js" */),
  "component---src-pages-sell-js": () => import("./../../../src/pages/sell.js" /* webpackChunkName: "component---src-pages-sell-js" */)
}

